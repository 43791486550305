/* Collapsable chapter list start */
.accordionContainer {
	cursor: pointer;
	border-radius: 0.5rem;
}

.accordionContent {
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 500ms ease-in-out;
	font-size: 1.5rem;
}

.accordionContent[aria-hidden="false"] {
	grid-template-rows: 1fr;
}

.accordionContent > div {
	overflow: hidden;
}

.checkboxContainer {
	display: flex;
	flex-direction: column;
	margin: 1rem;
	/* gap: 1rem; */
}

.checkboxContainer .checkbox {
	padding-left: 1rem;
	padding: 1rem;
	display: grid;
	grid-template-columns: 1fr 15fr;
	column-gap: 1rem;
	color: black;
	text-decoration: none;
	align-items: center;
}

.checkboxContainer .checkbox:hover {
	background-color: var(--shadow);
}

.checkboxContainer .checkbox.active,
.checkboxContainer .checkbox.active input {
	background-color: var(--secondary);
	color: white;
	accent-color: var(--shadow);
}

.checkboxContainer .checkbox input {
	width: 2rem;
	height: 2rem;
	cursor: pointer;
	accent-color: var(--secondary);
}

.checkboxLabel {
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.accordion {
	color: var(--text);
	padding: 1rem;
}

.accordionPanel {
	border-bottom: 1px solid;
	margin-bottom: 1rem;
}
.accordionPanel > .panelContainer {
	padding: 1rem;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.accordionPanel > .panelContainer:hover {
	background-image: linear-gradient(var(--shadow), white);
	transition: 500ms linear ease-in-out;
}

.accordionPanel > .panelContainer {
	position: relative;
}
.panelTitleContainer {
	cursor: pointer;
	display: flex;
	align-items: center;
}
.panelTitleContainer > .panelIndex {
	border-radius: 50%;
	font-size: 2rem;
	padding: 5px 1rem;
	margin: 0 1rem 1rem 0;
	box-shadow: 0 0 2px 0px black;
	display: inline-block;
}
.panelHeader {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	row-gap: 5px;
	padding-left: 1rem;
}
.panelTitle {
	font-size: 1.6rem;
	font-weight: 700;
	font-family: sans-serif;
}
.panelSubtitle {
	font-size: small;
}
.accordionTrigger {
	background: transparent;
	border: 0;
	font: inherit;
	color: inherit;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.accordionTrigger svg {
	font-size: 3rem;
	transition: transform 500ms;
}

.accordionTrigger[aria-expanded="true"] svg {
	transform: rotate(180deg);
}
/* Accordion container ends */
@media (max-width: 768px) {
	.accordionTrigger svg {
		font-size: small;
	}
	.panelSubtitle {
		font-size: small;
	}
	.panelTitle {
		font-size: small;
	}
	.panelTitleContainer > .panelIndex {
		font-size: small;
	}
	.accordionContent {
		font-size: small;
	}
}
