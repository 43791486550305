.cardContainer {
	box-shadow: 0 0 0 1px var(--shadow);
	padding: 2rem;
	border-radius: 1rem;
	overflow: auto;
	background-color: white;
}
.cardContainer .heading {
	font-size: 1.8rem;
	font-weight: 700;
	height: 4rem;
	line-height: 3rem;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.cardContainer p {
	font-size: 1.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
	height: 6rem;
	line-height: 2rem;
	color: gray;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}
.cardContainer button {
	background-color: white;
	color: gray;
	padding: 1rem;
	box-shadow: 0 0 0 1px var(--shadow);
	border: none;
	border-radius: 1rem;
}
.cardContainer button:hover {
	color: black;
	background-color: #f8fafc;
	cursor: pointer;
}
@media (max-width: 768px) {
	.cardContainer .heading {
		font-size: 1.5rem;
		height: 4rem;
		line-height: 2rem;
		overflow: hidden;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		white-space: break-spaces;
	}
	.cardContainer p {
		font-size: 1rem;
	}
}
@media (max-width: 425px) {
	.cardContainer .heading {
		font-size: 1.5rem;
		height: 4rem;
		line-height: 2rem;
		overflow: hidden;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.cardContainer p {
		font-size: 1.2rem;
	}
}
