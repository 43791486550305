.descriptionContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	font-size: 1.6rem;
	padding: 2rem 6rem;
}
.descriptionContainer h1 {
	border-bottom: 1px solid gray;
	padding-bottom: 1rem;
	width: 100%;
}
.descriptionHeading {
	font-weight: 500;
	font-size: 1.6rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
}
.descriptionHeading span:first-child {
	padding: 0 1rem 0 0;
}

.descriptionHeading span:last-child {
	padding: 0 0 0 1rem;
}
.descriptionContent {
	color: gray;
}
