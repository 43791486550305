.blogsContainer {
	display: flex;
	flex-direction: column;
	background-color: #f8fafc;
	padding: 2rem 10rem 5rem 10rem;
	row-gap: 1rem;
}
@media (max-width: 1000px) {
	.blogsContainer {
		padding: 2rem;
	}
}
