.footerContainer {
	grid-row: 3/4;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(20, 20, 47);
	outline: 1px solid white;
	color: white;
	text-align: center;
	height: 11.6rem;
	font-size: large;
}
@media (max-width: 860px) {
	.footerContainer {
		font-size: medium;
	}
}
@media (max-width: 425px) {
	.footerContainer {
		font-size: small;
	}
}
