.mainContainer {
	display: grid;
	height: 100%;
	position: relative;
}

.banner {
	grid-row: 1/2;
	text-align: center;
	margin: 0 auto;
	display: flex;
	align-self: center;
}

.banner h1 {
	font-size: 4rem;
	font-weight: 600;
}
.content {
	display: flex;
	flex-direction: column;
}
.tabsContainer {
	position: sticky;
	top: 6.33rem;
	z-index: 1;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4.6rem;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
	margin-bottom: 4rem;
}
.tabsContainer a {
	text-decoration: none;
	color: rgb(99, 96, 96);
	fill: rgb(99, 96, 96);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	padding: 1.5rem;
	cursor: pointer;
	gap: 5px;
	width: 18rem;
}
.tabsContainer a:hover {
	background-color: hsla(240, 4%, 77%, 0.2);
}

.tabsContainer a.active {
	color: black;
	font-weight: 500;
	position: relative;
}
.tabsContainer a.active:after {
	display: block;
	content: "";
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 4px;
	background-color: rgb(230, 72, 73);
	background-color: var(--accent);
}

.cardsContainer {
	margin: 8rem 13.2rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
	row-gap: 2rem;
	column-gap: 1rem;
	text-align: center;
	background-color: rgb(247, 248, 253);
}

@media (max-width: 1000px) {
	.tabsContainer svg {
		display: none;
	}
	.tabsContainer a {
		width: 10rem;
	}
	.banner h1 {
		font-size: 3.5rem;
	}
}
@media (max-width: 860px) {
	.tabsContainer a {
		font-size: small;
		padding: 1.5rem;
		width: auto;
	}
	.banner h1 {
		font-size: 3rem;
	}
}
@media (max-width: 425px) {
	.banner h1 {
		font-size: 2rem;
	}
}
