.cardContainer {
	margin: 1rem 1.2rem;
	display: grid;
	grid-template-rows: 2fr 3fr;
	text-align: center;
	box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background-color: var(--secondary);
	border-radius: 5px;
	outline: 1px solid black;
	cursor: pointer;
}

.cardContainer:hover,
.cardContainer:focus {
	transform: translate(0, -6px) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
	box-shadow: 0 1.2rem 2rem 0 rgba(0, 0, 0, 0.3);
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 0.5s;
}

.contentContainer {
	display: grid;
	grid-template-rows: 2fr 1fr;
}
.imgContainer {
	width: 100%;
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
	outline: 1px solid white;
}
.imgContainer img {
	width: 100%;
}

.labelContainer {
	color: white;
	text-align: start;
	padding: 0 2rem;
}

.labelContainer h1 {
	font-size: 2rem;
}

.labelContainer p {
	font-size: 1.4rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.bottomContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 1.5rem;
}

.bottomContainer h2 {
	color: white;
}

.bottomContainer a {
	text-decoration: none;
	display: flex;
	align-items: center;
	font-size: medium;
	padding: 1rem 2rem;
	cursor: pointer;
	background-color: rgb(32, 32, 51);
	color: white;
	outline: 1px solid white;
	border: none;
	gap: 5px;
}
.bottomContainer a:hover,
.bottomContainer a:focus {
	background-color: var(--accent);
}
