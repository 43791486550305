.navContainer {
	height: 6.33rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--bg);
	position: sticky;
	top: 0;
	z-index: 3;
	box-shadow: 0px 0px 1px 0 gray;
}
.logo {
	margin-left: 1rem;
	margin-top: 1rem;
	width: 15rem;
	overflow: hidden;
	cursor: pointer;
}
.logo img {
	width: 100%;
	height: 100%;
}
.navLinks ul {
	margin: 0 1rem;
	padding: 0;
	display: flex;
	gap: 1rem;
}

.navLinks li {
	list-style: none;
}

.navLinks li {
	display: flex;
	align-self: center;
	text-decoration: none;
	color: black;
	padding: 1rem;
	font-size: 3rem;
	cursor: pointer;
	border-radius: 50%;
}

.navLinks li:hover {
	background-color: gray;
}
.navLinks li:hover svg {
	color: black !important;

	fill: black !important;
}

.active {
	position: relative;
	transition: 0.5s linear;
	background-color: gray;
}

.active:after {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	background-color: var(--accent);
	height: 2px;
	width: 3rem;
}

.dropdown {
	position: absolute;
	top: 7rem;
	width: 28rem;
	height: 20rem;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	transition: 0.3s ease-in-out 0s;
	right: 3px;
	margin-right: 4px;
	display: none;
	box-shadow: 0px 5px 8px 0 rgba(255, 255, 255, 0.5);
	border-radius: 1rem;
	background-color: rgb(34, 35, 53);
}

.dropdown::-webkit-scrollbar {
	display: none;
}

.dropdownActive {
	display: block;
	height: auto;
}

.dropdownContent {
	background-color: rgb(34, 35, 53);
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
	padding: 2rem;
	border-radius: 1rem;
	height: 20rem;
}

.dropdownCardContainer {
	border: 2px solid white;
	margin: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	column-gap: 1rem;
	border-radius: 1rem;
	text-decoration: none;
	color: white;
}
.dropdownCardContainer:hover {
	cursor: pointer;
	background-color: var(--accent);
}

.dropdownCardLogoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4rem;
}
.dropdownCardLogoContainer img,
.dropdownCardLogoContainer svg {
	width: 50%;
	font-size: 4rem;
	display: flex;
	justify-content: center;
}
.dropdownLabel {
	font-size: 1.4rem;
	padding: 5px 0;
	text-align: center;
}

.viewProfile {
	margin: 1rem 1rem 0 1rem;
	display: flex;
	color: white;
	outline: 1px solid white;
}
.viewProfile:hover {
	cursor: pointer;
	background-color: rgb(53, 53, 86);
}
.profilePicContainer {
	padding: 1rem;
}

.profilePicContainer svg {
	font-size: 5rem;
}

.profileName {
	font-size: 2rem;
	font-weight: 500;
	padding: 1rem 0 0 0;
}
.goToProfile {
	text-decoration: none;
	font-size: 1.3rem;
	padding: 0 1rem 0 0;
	cursor: pointer;
	color: white;
}
.goToProfile:hover {
	text-decoration: underline;
	text-decoration-color: white;
}
.profileDropdowns {
	outline: 1px solid white;
	margin: 0 1rem;
	padding: 2rem;
	font-size: 1.5rem;
	gap: 1rem;
	display: flex;
	cursor: pointer;
	color: white;
	text-decoration: none;
}

.profileDropdowns svg {
	fill: white;
	font-size: 2rem;
}

.profileDropdowns:hover {
	background-color: rgb(53, 53, 86);
}

.dropdown.dropdownActive > a:last-child {
	margin-bottom: 1rem;
}
