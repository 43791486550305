.blogPageContainer {
	display: flex;
	flex-direction: column;
	padding: 5rem 18rem;
	font-family: "Plus Jakarta Sans", ui-sans-serif, system-ui, -apple-system,
		BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
		sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
	background-color: #f8fafc;
	line-height: 3.2rem;
}
.title {
	text-align: center;
	font-size: 4.5rem;
	font-weight: 700;
	line-height: 8rem;
}
.body {
	padding: 0 5rem;
	font-size: 2rem;
}

.body p,
.body li {
	color: black;
	font-weight: 350;
}
@media (max-width: 768px) {
	.blogPageContainer {
		padding: 5rem 10rem;
	}
	.title {
		text-align: center;
		font-size: 2.5rem;
		font-weight: 700;
		line-height: 8rem;
	}
	.body {
		padding: 0 5rem;
		font-size: 1.5rem;
	}
}
@media (max-width: 426px) {
	.blogPageContainer {
		padding: 5rem 5rem;
	}
	.title {
		text-align: center;
		font-size: 2.5rem;
		font-weight: 700;
		line-height: 3rem;
	}
	.body {
		padding: 0 2rem;
		font-size: 1.5rem;
		line-height: 2rem;
	}
}
