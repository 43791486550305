.cardsContainer {
	padding: 3rem 12rem 7rem 12rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(31rem, 1fr));
	text-align: center;
	background-color: #f8fafc;
}
@media (max-width: 1000px) {
	.cardsContainer {
		padding: 3rem;
	}
}
