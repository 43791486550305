.courseDetailsContainer {
	position: relative;
}
.bannerContainer {
	background-color: rgba(32, 34, 57);
	background-image: linear-gradient(
		-50deg,
		var(--accent) 10%,
		var(--secondary) 80%
	);
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 5.2rem 8.4rem 5.2rem 8.4rem;
}

.bannerLabels {
	width: 100%;
	padding: 0 0 3.2rem 0;
}
.imgContainerBanner {
	display: none;
	width: 80%;
}

.imgContainerBanner img {
	width: 100%;
}
.courseSubtitle {
	font-size: medium;
}
.courseTitle {
	font-size: xx-large;
	font-weight: 700;
	padding: 8px 0;
}
.courseDescription {
	font-size: medium;
}

.contents {
	padding: 0 2rem;
	display: grid;
	grid-template-columns: 3fr 1fr;
}
.leftContainer {
	padding-left: 5rem;
	margin-top: 4rem;
}

.courseOverviewContainer {
	margin: 2rem 0 4rem 0;
}

.courseOverviewPara {
	font-size: 1.5rem;
	color: gray;
	padding: 0 1rem;
}
.courseOverviewPara > p {
	line-height: 1.5;
}

.leftContainer .courseContentsHeader {
	color: var(--text);
	font-size: 2.5rem;
	font-weight: bold;
	margin-left: 1rem;
	gap: 5px;
}
.courseContentsHeader {
	color: var(--text);
	font-size: x-large;
}

.rightContainer {
	position: relative;
}

.checkoutContainer {
	position: sticky;
	top: 7.8rem;
	display: grid;
	/* background-image: linear-gradient(var(--secondary), var(--shadow)); */
	color: var(--text);
	/* border-radius: 1rem; */
	padding: 2px;
	box-shadow: 0 1rem 1rem 1px var(--shadow), 0 0 1rem 0px var(--shadow);
}
.imgContainerCheckout {
	/* display: grid; */
}
.imgContainerCheckout img {
	width: 100%;
	/* height: 15rem; */
	/* border-radius: 1rem; */
}
.checkoutLabels {
	font-size: 1.5rem;
	font-weight: bold;
	padding: 2rem;
}

.checkoutLabels button {
	width: 100%;
	margin: 1rem 0;
	padding: 1rem;
	font-size: medium;
	background-color: var(--primary-light);
	color: var(--text-white);
	border: none;
	box-shadow: 0px 0px 1px 0 var(--text-black);
}

.checkoutLabels button:hover {
	background-color: var(--primary);
	cursor: pointer;
}

.checkoutModules > div {
	margin: 1rem;
}

.checkoutModules > div:last-child {
	color: gray;
	font-weight: normal;
}
@media (max-width: 900px) {
	.contents {
		grid-template-columns: none;
	}
	.imgContainerBanner {
		display: block;
	}
	.checkoutContainer {
		display: flex;
		justify-content: center;
	}
	.checkoutContainer > * {
		width: 100%;
	}
	.checkoutModules {
		display: flex;
		justify-content: center;
	}

	.imgContainerCheckout {
		/* grid-column: 1/2; */
		display: none;
	}
	.checkoutLabels {
		grid-column: 2/4;
	}

	.leftContainer {
		grid-row: 2/3;
		grid-column: 1/2;
	}
	.rightContainer {
		grid-column: 1/2;
		grid-row: 1/2;
	}
}
