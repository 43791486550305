.mainContainer {
	background-color: #f7f8fa;
	height: 89.5vh;
	display: grid;
	grid-template-columns: 3.5fr 6.5fr;
	transition: grid-template-columns 500ms ease-in-out;
}

.mainContainer.open {
	grid-template-columns: 35rem 7fr;
}
.mainContainer.close {
	grid-template-columns: 0 1fr;
}
.sidebarContainer {
	background-color: white;
	margin: 2rem;
	overflow: hidden;
	grid-column: 1/2;
	border-radius: 1rem;
	box-shadow: 0 0 0px 1px var(--shadow);
}

.sidebarContainer::-webkit-scrollbar {
	display: none;
}

.backButton {
	box-shadow: 0 0 0px 1px var(--shadow);
	color: var(--text);
	padding: 1rem;
	display: flex;
	align-items: center;
	font-size: medium;
	column-gap: 1rem;
	font-weight: 500;
	text-decoration: none;
}
.backButton > svg {
	fill: gray;
}
.backButton:hover {
	background-color: var(--shadow);
}
.courseTitle {
	padding: 1rem;
	padding-left: 2rem;
	font-size: 2.5rem;
	font-weight: bold;
}
.progressBarContainer {
	box-shadow: 0 0 0px 1px var(--shadow);
	padding: 1rem 0;
	padding-left: 2rem;
}
.progressPercent {
	padding: 1rem 0;
	width: 100%;
	font-size: medium;
}
.progressBar {
	height: 8px;
	border-radius: 5px;
	background-color: var(--shadow);
	width: 95%;
}
.progress {
	outline: none;
	border: none;
	height: 100%;
	width: 2%;
	overflow: hidden;
	background-color: rgb(123, 188, 26);
	border-radius: 5px 0 0 5px;
}

.dropdownContainer {
	height: 50.5vh;
	overflow-x: hidden;
	overflow-y: auto;
	/* To hide Scrollbar in sidebar in IE, Edge, Firefox*/
	-ms-overflow-style: none;
	scrollbar-width: none;
}
/* Hide scrollbar from sidebar */
.dropdownContainer::-webkit-scrollbar {
	display: none;
}
.contentContainer {
	background-color: white;
	grid-column: 2/3;
	overflow: auto;
	position: relative;
	box-shadow: 0 0 0px 1px var(--shadow);
}
.contentContainer::-webkit-scrollbar {
	display: none;
}
.toggleContainer {
	position: fixed;
	top: 25vh;
	z-index: 1;
}
.sidebarToggle {
	padding: 1rem;
	border-radius: 1rem;
	background-color: var(--shadow);
	color: var(--text);
	fill: var(--text);
	position: sticky;
	top: 35vh;
	left: 2px;
	font-size: 2rem;
	transition: 250ms;
	transform: rotate(180deg);
	cursor: pointer;
}

.sidebarToggle:hover {
	background-color: var(--primary);
	fill: white;
}

.sidebarToggle[aria-expanded="true"] {
	transform: rotate(0deg);
}

/* .toggleContainer::after .sidebarToggle[aria-expanded="false"]:hover {
	content: "Expand Sidebar";
	position: absolute;
	top: 10rem;
} */

.videoPlayer > .reactPlayer {
	margin: 0 auto;
}

.tabsContainer {
	width: 100%;
	box-shadow: 0 0 0px 1px var(--shadow);
	display: flex;
	justify-content: center;
}
.tabsContainer div {
	box-shadow: 0 0 0px 1px var(--shadow);
	padding: 1rem 2rem;
	font-size: 1.5rem;
	text-decoration: none;
	color: var(--text);
	cursor: pointer;
}
.tabsContainer div:hover {
	background-color: var(--shadow);
}
.active {
	border-bottom: 5px solid var(--primary);
}
.tabsContentContainer {
	width: 100%;
}

@media (max-width: 768px) {
	.mainContainer {
		grid-template-columns: 0 4fr;
	}
	.backButton {
		font-size: small;
	}
	.courseTitle {
		font-size: 2rem;
	}
	.progressPercent {
		font-size: small;
	}
	.mainContainer.open {
		grid-template-columns: 25rem 7fr;
	}
	.tabsContainer div {
		font-size: 1.5rem;
		padding: 5px 1rem;
	}
}
@media (max-width: 468px) {
	.tabsContainer div {
		font-size: 1rem;
		padding: 5px 1rem;
	}
}
