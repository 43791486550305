.transcriptContainer {
	text-align: left;
	margin: 5rem;
	padding: 1rem;
	font-size: 1.5rem;
	outline: 1px solid var(--shadow);
	height: 40vh;
	overflow: auto;
}
.transcriptContainer p {
	color: gray;
}
